const MachineTelemetry = {
  computed: {
    hasTelemetry() {
      return Object.keys(this.telemetry).length > 0
    },
    hasCoordinates() {
      return this.$getDeep(this.telemetry, 'g.la') || this.$getDeep(this.telemetry, 'g.lo')
    },
    connectBoxClass() {
      return this.telemetryConnected
        ? this.hasTelemetry
          ? 'text-secondarylight'
          : 'text-muted'
        : 'text-primarylight'
    },
    googleMaps() {
      let lat = this.$getDeep(this.telemetry, 'g.la') || 0
      let lon = this.$getDeep(this.telemetry, 'g.lo') || 0
      return `https://maps.google.com/?q=${lat},${lon}&ll=${lat},${lon}&z=18&t=k`
    },
  },

  methods: {
    channelSubscribe() {
      if (!this.$cable._channels.subscriptions['Api::V1::Machine::TelemetryChannel']) {
        this.$cable.subscribe({
          channel: 'Api::V1::Machine::TelemetryChannel',
          id: this.machine_id,
        })
      }
    },
    decRound(num, decimalPlaces = 0) {
      const p = Math.pow(10, decimalPlaces)
      return Math.round(num * p) / p
    },
    clearTelemetry() {
      if (this.telemetryAlive && ((Date.now() / 1000) | 0) - this.telemetryAlive >= 10)
        this.telemetry = {}
    },
    k4MotorRange(value) {
      return Math.min(
        Math.max(
          this.decRound(
            -100 + this.k4Slope * ((value || this.k4InputMiddle) - this.k4InputStart),
            0
          ),
          -100 /* MIN */
        ),
        100 /* MAX */
      )
    },
  },

  channels: {
    'Api::V1::Machine::TelemetryChannel': {
      connected() {
        this.telemetryConnected = true
      },
      rejected() {
        this.telemetryConnected = false
      },
      received(response) {
        this.telemetry = response
        this.telemetryAlive = (Date.now() / 1000) | 0
      },
      disconnected() {
        this.telemetryConnected = false
      },
    },
  },

  data() {
    return {
      machine_id: this.$route.params.machine_id,
      telemetry: {},
      telemetryTimer: null,
      telemetryAlive: null,
      telemetryConnected: false,
      /**
       * input_end = 0.375
       * input_start = 0.06
       * output_end = 100
       * output_start = -100
       * slope = (output_end - output_start) / (input_end - input_start)
       * slope = 634.920634921
       * output = output_start + slope * (input - input_start)
       **/
      k4InputStart: 0.06,
      k4InputMiddle: 0.218,
      k4Slope: 634.920634921,
    }
  },

  mounted() {
    this.telemetryTimer = setInterval(this.clearTelemetry, 5000)
    this.channelSubscribe()
  },

  destroyed() {
    clearInterval(this.telemetryTimer)
  },
}

export default MachineTelemetry
